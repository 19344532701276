import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Event from "./components/Gallery/Event/Event";
import Infrastructure from "./components/Gallery/Infrastructure/Infrastructure";
import Facilities from "./components/Facilities/Facilities";
import Courses from "./components/Course/Courses";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import Footer from "./components/Footer/Footer";
import Faculties from "./components/Gallery/Faculties/Faculties";
import Header from "./components/Header/Header";

const App = () => {
  return (
    <>
    <Header/>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/event" element={<Event />}></Route>
        <Route path="/infrastructure" element={<Infrastructure />}></Route>
        <Route path="/faculties" element={<Faculties />}></Route>
        <Route path="/courses" element={<Courses />}></Route>
        <Route path="/facilities" element={<Facilities />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
      <Footer />
    </>
  );
};

export default App;
