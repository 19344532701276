import React from 'react'
import { NavLink } from "react-router-dom";

const AboutDetails = () => {
  return (
    <div>
      <div className="edu-courses about-edu-courses" data-aos="fade-up">
          <div className="container">
            <div className="row panel-grid-banner">
              <div className="col-sm-4 col-xs-12">
                <div className="inner-grid">
                  <div className="text-courses">
                    <i className="fa fa-laptop" />
                    <h3>Learn Courses With US</h3>
                  </div>
                  <div className="courses-content">
                    <p>
                      Auci elit cons equat ipsutis sem nibh id elit. Duis sed
                      odio sit amet nibh vulputate cursus a sit amet
                    </p>
                    <NavLink to="/Courses" className="mt_btn_yellow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="inner-grid">
                  <div className="text-courses">
                    <i className="fa fa-users" />
                    <h3>Highly Qualified Teachers</h3>
                  </div>
                  <div className="courses-content">
                    <p>
                      Auci elit cons equat ipsutis sem nibh id elit. Duis sed
                      odio sit amet nibh vulputate cursus a sit amet
                    </p>
                    <NavLink to="/faculties" className="mt_btn_yellow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="inner-grid">
                  <div className="text-courses">
                    <i className="fa fa-book" />
                    <h3>Book Library &amp; Stores</h3>
                  </div>
                  <div className="courses-content">
                    <p>
                      Auci elit cons equat ipsutis sem nibh id elit. Duis sed
                      odio sit amet nibh vulputate cursus a sit amet
                    </p>
                    <NavLink to="/facilities" className="mt_btn_yellow">
                      Read More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AboutDetails
