import React, {useEffect} from "react";
import AOS from "aos"
import "aos/dist/aos.css"
import AboutClg from "./AboutClg";
import AboutDetails from "./AboutDetails";
import AboutMission from "./AboutMission";
import AboutTestimonals from "./AboutTestimonals";
import AboutChoose from "./AboutChoose";
import { NavLink } from "react-router-dom";

const About = () => {
useEffect(()=>{
  AOS.init({duration:2000})
})
  return (
    <>
      <div>
        <div id="blog_banner">
          <div className="page-title">
            <div className="container">
              <h2>About Us</h2>
            </div>
          </div>
          <div className="black-overlay" />
        </div>
        <div className="breadcrumb-main">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>

        <AboutClg/>
        <AboutDetails/>
        <AboutMission/>
        <AboutTestimonals/>
        <AboutChoose/>
        
        
      </div>
    </>
  );
};
export default About;
