import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./header.css"

const Header = () => {
  
  return (
<>
     {/* <header id="inner-navigation"> */}
    <div className="topbar-section">
      <div className="container">
        <div className="topbar-inner">
          <div className="top-bar-left pull-left clg-discripton">
          <h1> Geeta Shikshan Mahavidyalaya </h1>
            <p>Recognised by Govt. of Maharashtra & Affilated to RTM Nagpur University, Nagpur</p>
            <p>Recognition from (N.C.T.E) and (W.R.C.) New Delhi.</p>
            {/* <ul>
              <li><i className="fa fa-phone" /> Phone: +91 0712-2226700</li>
              <li><i className="fa fa-envelope" /> Email: <NavLink to="mailto:info@geetacollege.org" className="__cf_email__" >info@geetacollege.org</NavLink></li>
            </ul> */}
          </div>
          <div className="top-bar-right pull-right">
            {/* <ul>
              <li><NavLink to='/faq'><i className="fa fa-question-circle" /> Ask a Question</NavLink> </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
   {/* </header> */}
   <Navbar  sticky="top" expand="lg" className="nav-bar">
    <Container>
      {/* <Navbar.Brand href="#home">Geeta Shikshan Mahavidyalaya</Navbar.Brand> */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="m-auto nav-main">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavDropdown title="Gallery" id="basic-nav-dropdown">
            <NavDropdown.Item as={Link} to="/event">Event</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/infrastructure">Infrastructure</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/facilities">Facilities</NavDropdown.Item>
          </NavDropdown>
          <NavLink to="/faculties">Faculties</NavLink>
          <NavLink to="/courses">Courses</NavLink>
          <NavLink to="/Contact">Contact</NavLink>
        </Nav> 
      </Navbar.Collapse>
    </Container>
  </Navbar>

   </>
  )
}

export default Header
