import React from 'react'

const CourseCard = () => {
  return (
    <>
     <section id="mt_services" className="light-bg services-section section-inner">
    <div className="container">
      <div className="inner-heading">
        <h3>Featured courses</h3>
        <h2>Best courses to choose from</h2>
      </div>
      <div className="row slider-ft-course">
        <div className="col-md-4 col-sm-6 col-xs-12 item mb-5">
          <div className="featured-item">
            <div className="feat-img">
              <img src="assets/images/education/ft-1.jpg" alt='' />
              <div className="th-name">
                {/* <img src="images/blog/cmnt-1.jpg" alt='' />
                <h5>Mark Ronson</h5> */}
              </div>
              <div className="overlayPort">
                {/* <ul className="info text-center list-inline">
                  <li>
                    <a href="courses-detail.html">
                      <h4>View Detail</h4>
                    </a>
                  </li>
                 </ul>  */}
              </div>
            </div>
            <div className="feat-inn">
              {/* <span>(20 Reviws)</span>
              <ul>
                <li><i className="fa fa-star" /></li>
                <li><i className="fa fa-star" /></li>
                <li><i className="fa fa-star" /></li>
                <li><i className="fa fa-star" /></li>
                <li><i className="fa fa-star" /></li>
              </ul> */}
              <h2>Bachelor of Education (B.Ed) </h2>
              <h5>Intake: 50</h5>
              <h5>Duration : 2 Year</h5>
              <h5>tuation fee : 37808 (Two Year Fees)</h5>
              
              <div className="course-feat">
                <div className="price-ft float-left">
                  {/* <span>$90</span> */}
                </div>
                <div className="admin-ft float-right">
                  {/* <ul>
                    <li><a href="#" tabIndex={-1}><i className="fa fa-user" /><span>31</span></a></li>
                    <li><a href="#" tabIndex={-1}><i className="fa fa-heart" /><span>10</span></a></li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
    </>
  )
}

export default CourseCard
