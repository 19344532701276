import React from "react";
import { NavLink } from "react-router-dom";
import UpComingEvent from "./UpComingEvent";

const Event = () => {
  return (
    <>
      <div>
        <div id="blog_banner">
          <div className="page-title">
            <div className="container">
              <h2>Events</h2>
            </div>
          </div>
          <div className="black-overlay" />
        </div>
        <div className="breadcrumb-main">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li className="active">Event</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <PastEvents /> */}
      <UpComingEvent />
    </>
  );
};

export default Event;
