import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
          <footer id="mt_footer">
          <div className="container">
            <div className="footer-main">
              <div className="row">
                <div className="col-md-5 col-sm-12 col-xs-12">
                  <div className="follow_us">
                    <h2><NavLink to="/">Geeta Shikshan Mahavidyalaya </NavLink></h2>
                    <p> Our College is a teacher training Service provider and conduct B.Ed. Course.</p>
                    <div className="textwidget">
                      <p className="white">Address : Shri Vijay S. Kaushal Dubey Lay Out, Jaitala Road, Nagpur. 440036
                        <br /></p>
                      <p className="white">Mon-Sat: 8:30 – 14:30<br />
                        Sunday Closed</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-6 col-xs-12">
                  <div className="footer-right">
                    <div className="row">
                      <div className="col-md-6 col-xs-12">
                        <div className="mt_contact_ftr">
                          <h3>Quick Links</h3>
                          <ul className="footer-quick-links-4">
                            <li className="active">
                              <NavLink to='/'>Home</NavLink>
                            </li>
                            <li>
                              <NavLink to="/about">About</NavLink>
                            </li>
                            <li>
                              <NavLink to="/event">Event</NavLink>
                            </li>
                            <li>
                              <NavLink to="/infrastructure">Infrastructure</NavLink>
                            </li>
                            <li>
                              <NavLink to="/facilities">Facilities</NavLink>
                            </li>
                            <li>
                              <NavLink to="/faculties">Faculties</NavLink>
                            </li>
                            <li>
                              <NavLink to="/Course">Course</NavLink>
                            </li>
                            <li>
                              <NavLink to="/contact">Contact</NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 col-xs-12">
                        <div className="mt_post_ftr">
                          <h3>Instagram</h3>
                          <div className="insta-list">
                            <div className="row">
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_01.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_02.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_03.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_04.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_05.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_06.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_07.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_08.jpg" alt="insta" />
                              </div>
                              <div className="col-sm-4">
                                <img src="assets/images/insta/insta_09.jpg" alt="insta" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt_footer_copy">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="copy_txt">
                    <p className="mar-0 white">© Copyright 2023 - Nass Technologies Pvt.Ltd.</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="follow_us pull-right">
                    <ul className="social_icons">
                      <li><NavLink target="_blank" to="https://www.facebook.com/login/"> <i className="fa fa-facebook" /></NavLink></li>
                      <li><NavLink target="_blank" to="https://twitter.com/i/flow/login"><i className="fa fa-twitter" /></NavLink></li>
                      <li><NavLink target="_blank" to="https://www.instagram.com/"><i className="fa fa-instagram" /></NavLink></li>
                      <li><NavLink target="_blank" to="https://www.linkedin.com/"><i className="fa fa-linkedin" /></NavLink></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <NavLink id="back-to-top" to="/" className="btn btn-primary btn-lg back-to-top" role="button" title data-placement="left">
          <span className="fa fa-arrow-up" />
        </NavLink> */}

    </>
  );
};

export default Footer;
