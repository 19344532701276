import React from 'react'
import { NavLink } from 'react-router-dom'

const Facilities = () => {
  return (
    <>
  <div>
   <div>
  <div id="blog_banner">
    <div className="page-title">
      <div className="container">
        <h2>Facilities</h2>
      </div>
    </div>
    <div className="black-overlay" />
  </div>
  <div className="breadcrumb-main">
    <div className="container">
      <ul className="breadcrumb">
        <li><NavLink to="index.html">Home</NavLink></li>
        <li><NavLink to="#">Facilities</NavLink></li>
        {/* <li className="active">Event List One</li> */}
      </ul>
    </div>
  </div>
</div>








    <section className="edu-events section-inner">
  <div className="container">
    <div className="inner-heading">
      <h3>Our Facilities</h3>
      <h2>Well Infrastructure & Building</h2>
    </div>
    <div className="row">
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">1 </h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Fully Equipped Education Technology Lab</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">2</h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Education Psychology Lab</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">3</h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Well Maintained Library</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">4 </h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Well Qualified Staff</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">5 </h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Computer Lab</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 col-sm-12">
        <div className="event-item">
          <div className="event-date text-center text-uppercase">
            <h4 className="mar-0">6 </h4>
          </div>
          <div className="event-details">
            <h3 className="mar-bottom-10"><NavLink to="#">Playground</NavLink></h3>
            {/* <ul className="event-time">
              <li><i className="fa fa-clock-o" />11:00 AM - 3:00 PM</li>
              <li><i className="fa fa-map-marker" />Royal National Park, Sydney</li>
            </ul> */}
          </div>
        </div>
      </div>
      {/* <div className="col-xs-12">
        <div className="pagination-div pg-services text-center">
          <ul className="pagination">
            <li className="prev">
              <NavLink to="#"> &lt;&lt; </NavLink>
            </li>
            <li>
              <NavLink to="#">1</NavLink>
            </li>
            <li className="active">
              <NavLink to="#">2</NavLink>
            </li>
            <li>
              <NavLink to="#">3</NavLink>
            </li>
            <li>
              <NavLink to="#">...</NavLink>
            </li>
            <li>
              <NavLink to="#">10</NavLink>
            </li>
            <li className="next">
              <NavLink to="#"> &gt;&gt; </NavLink>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  </div>
</section>

  </div>
    </>
  )
}

export default Facilities
