import React from 'react'
// import ErrorImg from 'assets/images/ErrorImg.png'
import { NavLink } from 'react-router-dom'
import './errorpage.css'
const ErrorPage = () => {
    return (
       <>
       <div id="blog_banner">
        <div className="page-title">
          <div className="container">
            <h2>Page Not Found</h2>
          </div>
        </div>
        <div className="black-overlay" />
      </div>
      <div className="breadcrumb-main">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="active">Error Page</li>
          </ul>
        </div>
      </div>
        <div className='error-box'>
            <div>
                <img src='assets/images/ErrorImg.png' alt="ErrorImg" />
                <h1>WE ARE SORRY, PAGE NOT FOUND</h1>
                <p>THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS NAME CHANGE OR IS TEMPORARILY UNAVAILABLE</p>
                <NavLink to='/'><button>BACK TO HOMEPAGE</button></NavLink>
            </div>
        </div>
        </>
    )
}

export default ErrorPage  
