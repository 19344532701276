import React from 'react'
import { NavLink } from 'react-router-dom'
import TeachingStaff from './TeachingStaff'
import NonTeachingStaff from './NonTeachingStaff'

const Faculties = () => {
  return (
    <>
      <div>
  <div id="blog_banner">
    <div className="page-title">
      <div className="container">
        <h2>Faculties</h2>
      </div>
    </div>
    <div className="black-overlay" />
  </div>
  <div className="breadcrumb-main">
    <div className="container">
      <ul className="breadcrumb">
        <li><NavLink to="/">Home</NavLink></li>
        <li className="active">Faculties</li>
      </ul>
    </div>
  </div>
  
  {/* Teaching staff */}
<TeachingStaff/>


{/* non teaching staff */}
<NonTeachingStaff/>
</div>


    </>
  )
}

export default Faculties
