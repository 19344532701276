import React from 'react'
import { NavLink } from 'react-router-dom'

const Infrastructure = () => {
  return (
    <>
   
   <div>
  <div id="blog_banner">
    <div className="page-title">
      <div className="container">
        <h2>Infrastructure</h2>
      </div>
    </div>
    <div className="black-overlay" />
  </div>
  <div className="breadcrumb-main">
    <div className="container">
      <ul className="breadcrumb">
        <li><NavLink to="index.html">Home</NavLink></li>
        <li><NavLink to="#">Gallery</NavLink></li>
        <li className="active">Infrastructure</li>
      </ul>
    </div>
  </div>
  <section id="the-gallery" className="wide-gallery inner-gallery section-inner">
    <div className="container">
      <div className="inner-heading">
        <h3>Infrastructure</h3>
        <h2>See and feel it</h2>
      </div>
      <div className="row ge_second">
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-02.jpg" title>
              <img src="assets/images/gallery/s-1.jpg" alt="img1" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-01.jpg" title>
              <img src="assets/images/gallery/building.jpeg" alt="img2" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-03.jpg" title>
              <img src="assets/images/gallery/gl-03.jpg" alt="img3" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-04.jpg" title>
              <img src="assets/images/gallery/gl-04.jpg" alt="img4" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-02.jpg" title>
              <img src="assets/images/gallery/Lab.jpg" alt="img5" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-01.jpg" title>
              <img src="assets/images/gallery/Lab2.jpg" alt="img6" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-03.jpg" title>
              <img src="assets/images/gallery/gl-03.jpg" alt="img7" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-04.jpg" title>
              <img src="assets/images/gallery/gl-04.jpg" alt="img8" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
        <div className="col-sm-4 mix">
          <div className="item port-popup">
            <NavLink to="assets/images/gallery/gl-05.jpg" title>
              <img src="assets/images/gallery/gl-05.jpg" alt="img9" />
              <i className="fa fa-search" />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

   
    </>
  )
}

export default Infrastructure
