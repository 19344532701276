import React from 'react'

const TeachingStaff = () => {
  return (
    <>
     <section id="educate-team" className="section-inner">
    <div className="container">
      <div className="inner-heading">
        <h3>Our Teachers</h3>
        <h2>TEACHING-STAFF</h2>
        <h2>Meet our expert teachers</h2>
      </div>
      <div className="row slider-educteam">
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-1.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              <div className="about-tech">
                {/* <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p> */}
                {/* <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul> */}
              </div>
            </div>
            <div className="member_name">
              <h3>Dr.Rajesh B Khillare</h3>
              <span>Principal</span>
              <span>Qualification-(B.A, M.A, B.ED, M.ED, Ph.D, <br /> NET, SET)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-2.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              <div className="about-tech">
                {/* <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="https://www.linkedin.com"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                </ul> */}
              </div>
            </div>
            <div className="member_name">
              <h3>Shree Mahesh C.Gade</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A, B.Ed, <br /> M.Ed, <br /> SET, NET)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-3.jpg" alt="team" />
            </figure> */}
             <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                </ul>
              </div> */}
            </div> 
            <div className="member_name">
              <h3>Ku.Aparna V.Chaudhari</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.Sc, B.Ed) <br /> M.A(English) , <br /> M.A(Psychology), M.Ed</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member  mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Ku. Pushpa R.Gautam</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A(History), M.Com, M.Ed, <br />NET(Edu))</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-1.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>ShreeRavi L.<br />  Jadhav</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A, B.Ed,<br /> M.Ed, SET)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-2.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Shree Shrikant S. Anndate</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A) <br /> B.Ed,<br /> M.Ed, SET</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-3.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Chitra D.<br />  vaidya</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.Sc,<br /> B.Ed, M.Ed)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Shree Ravi R.<br />  Sharma</h3>
              <span>Lecturer</span>
              <span>Qualification-(B.P.Ed,<br /> M.P.Ed)</span>
            </div>
          </div>
        </div>
               <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Sunanda D. Shabde</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A, <br /> B.Ed, <br />  M.Ed)</span>
            </div>
          </div>
        </div>
         <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Vaishali A. Umrekar</h3>
              <span>Lecturer</span>
              <span>Qualification-(M.A. socialogy, M.Ed,Teacher in Handcraft And work experience)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 
    </>
  )
}

export default TeachingStaff
