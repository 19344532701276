import React from 'react'

const AboutTestimonals = () => {
  return (
    <div>
      <section id="const-testi" className="edu-testimonial">
          <div className="container wow slideInDown" data-aos="fade-up">
            <div className="row ">
              <div className="col-sm-6 description">
                <div className="inner-heading">
                  <h3 className="white">Testimonials</h3>
                  <h2 className="white">Hear what our students have to say</h2>
                  <div className="testimonial-abt">
                    <p className="white">
                      We have some of our candidate who get successful from our college Geeta Shikshan Mahavidyalaya (GSM) , who are really happy for getting an amazing education by our college ,and it is very important to give your best to student ,because Education is Very Important.  

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row slider-eductestimo">
                  <div className="col-sm-4">
                    <div className="item">
                      <div className="testimonial_main">
                        <div className="client-pic">
                          <img src="assets/images/team/student1.jpeg" alt="" />
                        </div>
                        <h4>
                          <span>
                            CHRIST DANIEL
                          </span>
                          <span>BIOLOGY</span>
                        </h4>
                        <p>
                          If you want to get an amazing achievement and success
                          for your future you need to go Geeta Shikshan
                          Mahavidyalaya (GSM).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="item">
                      <div className="testimonial_main">
                        <div className="client-pic">
                          <img src="assets/images/team/student2.jpeg" alt='' />
                        </div>
                        <h4>
                          <span>
                            John Wakson
                          </span>
                          <span>Science &amp; Arts</span>
                        </h4>
                        <p>
                          Getting Education from here was a very good
                          opportunity I ever had.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="item">
                      <div className="testimonial_main">
                        <div className="client-pic">
                          <img src="assets/images/team/student3.jpeg" alt='' />
                        </div>
                        <h4>
                          <span>
                            Tim Loran
                          </span>
                          <span>Web Developer</span>
                        </h4>
                        <p>
                          When I came here First time I saw my self Successful
                          and got successful here for my future and now the
                          present is excelent.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default AboutTestimonals
