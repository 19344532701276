import React from 'react'
import { NavLink } from 'react-router-dom'

const AboutClg = () => {
  return (
    <>
             <section id="mt_about" >
          <div className="container" >
            <div className="about_services">
              <div className="row" data-aos="fade-up">
                <div className="col-sm-6">
                  <div className="about-items">
                    <div className="inner-heading">
                      <h3>About GSM</h3>
                      <h2 className="mar-0">Know more about GSM</h2>
                    </div>
                    <p className="NameofGSM">
                      Geeta Shikshan Mahavidyalaya (GSM) having following
                      Recognition and Affiliations:
                    </p>
                    <p>
                      1. Recognition from National Council of Teacher Education
                      (N.C.T.E) Western Regional Committee (W.R.C.) New Delhi.{" "}
                      <br /> 2. Permission from Government of Maharashtra.
                      <br /> 3. Affiliation from Rashtrasant Tukdoji Maharaj
                      Nagpur University.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="about-edu-bg">
                    <img src="assets/images/team/college-grp.png" alt="About" />
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className="about-form">
                    <div className="col-sm-9">
                      <div className="about-sch-form">
                        <div className="event-title" data-aos="fade-up">
                          <h2>Apply for Admission</h2>
                          <h3 className="white">
                            Admission for 2023 are now open
                          </h3>
                          <p>
                            We don’t just give students an education and
                            experiences that set them up for success in a
                            career. We help them succeed in their career—to
                            discover a field they’re passionate about and dare
                            to lead it.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="smt-items mar-top-70">
                        <NavLink className="mt_btn_yellow" to="/courses">
                          Apply Here
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
    </>
  )
}

export default AboutClg
