import React from 'react'
import CountUp from "react-countup";


const AboutChoose = () => {
  return (
    <div>
      <section id="mt_fun" className="about-pg-mt section-inner">
          <div className="container" data-aos="fade-up">
            <div className="mt-statts">
              <div className="inner-heading">
                <h3>Why choose us</h3>
                <h2>Best College for B.Ed Course</h2>
              </div>
              <div className="row facts_row">
                <div className="col-md-8 col-sm-8 col-xs-12 facts_col">
                  <div className="fun-facts-main">
                    <div className="fun-facts_wrapper">
                      <i className="fa fa-trophy" />
                      <p>
                        <CountUp
                          start={0}
                          end={150}
                          className="fun-facts_figure timer"
                        />
                      </p>
                      <span className="fun-facts_title"> AWARDS </span>
                    </div>
                    <div className="fun-facts_wrapper">
                      <i className="fa fa-graduation-cap" />
                      <p>
                        <CountUp
                          start={0}
                          end={2350}
                          className="fun-facts_figure timer"
                        />
                      </p>
                      <span className="fun-facts_title"> STUDENTS </span>
                    </div>
                    <div className="fun-facts_wrapper">
                      <i className="fa fa-university" />
                      <p>
                        <CountUp
                          start={0}
                          end={16}
                          className="fun-facts_figure timer"
                        />
                      </p>
                      <p />
                      <span className="fun-facts_title">
                        {" "}
                        YEARS OF HISTORY{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12">
                  <div className="nets-facts">
                    <h2>Take a tour</h2>
                    <div className="pulses">
                      <button
                        type="button"
                        className="play-btn js-video-button"
                        data-video-id={176543782}
                        data-channel="vimeo"
                      >
                        <i className="fa fa-play" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default AboutChoose
