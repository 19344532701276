import React from 'react'
import { NavLink } from 'react-router-dom'

const SliderBottomDiv = () => {
  return (
   
    <div className="edu-courses">
    <div className="container">
      <div className="row panel-grid-banner">
        <div className="col-md-4 col-sm-12">
          <div className="inner-grid text-center">
            <div className="text-courses">
              <i className="fa fa-laptop mar-bottom-20" />
              <h3>Learn Courses Online</h3>
            </div>
            <div className="courses-content">
              <p className="mar-top-20">Auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet</p>
              <NavLink to="/courses" className="mt_btn_yellow">Read More</NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div className="inner-grid text-center">
            <div className="text-courses">
              <i className="fa fa-users mar-bottom-20" />
              <h3>Highly Qualified Teachers</h3>
            </div>
            <div className="courses-content">
              <p className="mar-top-20">Auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet</p>
              <NavLink to="/courses" className="mt_btn_yellow">Read More</NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 col-xs-12">
          <div className="inner-grid text-center">
            <div className="text-coursesa">
              <i className="fa fa-book mar-bottom-20" />
              <h3>Book Library &amp; Stores</h3>
            </div>
            <div className="courses-content">
              <p className="mar-top-20">Auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet</p>
              <NavLink to="/courses" className="mt_btn_yellow ">Read More</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SliderBottomDiv
