import React from 'react'

const NonTeachingStaff = () => {
  return (
    <>
     <section id="educate-team" className="section-inner">
    <div className="container">
      <div className="inner-heading">
       
        <h2>NON-TEACHING-STAFF</h2>
        
      </div>
      <div className="row slider-educteam">
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-1.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              <div className="about-tech">
                {/* <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p> */}
                {/* <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul> */}
              </div>
            </div>
            <div className="member_name">
              <h3>Varsha Vasudeo <br />Ganvir</h3>
              <span>Librarian</span>
              <span>Qualification-(M.A.b.Lib)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-2.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              <div className="about-tech">
                {/* <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="https://www.linkedin.com"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                </ul> */}
              </div>
            </div>
            <div className="member_name">
              <h3>Manisha<br /> Khobragade</h3>
              <span>Lab Assistant</span>
              <span>Qualification-(B.C.A)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-3.jpg" alt="team" />
            </figure> */}
             <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                </ul>
              </div> */}
            </div> 
            <div className="member_name">
              <h3>Shree. Devanand D. Wanjari</h3>
              <span>Sr. clerk</span>
              <span>Qualification-B.A, <br /> D.T.P, MSCIT</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member  mar-bottom-30">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Sou Anita A.<br /> Hirudkar</h3>
              <span>Jr. Clerk</span>
              <span>Qualification-(B.A, B.Ed)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-1.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Shri. Satish <br />Ambule</h3>
              <span>Store Keeper</span>
              <span>Qualification-(HSC)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-2.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Sou.Sangita A. Aurangabadkar</h3>
              <span>Computer Tecnician</span>
              <span>Qualification-(PGDC) <br />.</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-3.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Karan V. <br />Mogre</h3>
              <span>Sweepar</span>
              <span>Qualification-(HSC)</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Shree. Ravindra<br /> Chanore</h3>
              <span>Peon</span>
              <span>Qualification-(SSC)</span>
            </div>
          </div>
        </div>
               <div className="col-md-3 col-sm-6 col-xs-12 item">
          <div className="team_member">
            {/* <figure className="effect-julia">
              <img src="assets/images/team/rmember-4.jpg" alt="team" />
            </figure> */}
            <div className="team-overlay">
              {/* <div className="about-tech">
                <p>On the other hand we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                <ul className="socialicons">
                  <li><NavLink to="#"><i className="fa fa-linkedin" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-google" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-twitter" /></NavLink></li>
                  <li><NavLink to="#"><i className="fa fa-facebook" /></NavLink></li>
                </ul>
              </div> */}
            </div>
            <div className="member_name">
              <h3>Shree. Nalu <br /> Nagose</h3>
              <span>Farnas</span>
              <span>Qualification-(SSC)</span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </section>
 
    </>
  )
}

export default NonTeachingStaff
