import React from 'react'
import { NavLink } from 'react-router-dom'
import CourseCard from './CourseCard'


const Courses = () => {
  return (
  <>
<div>
  <div id="blog_banner">
    <div className="page-title">
      <div className="container">
        <h2>Our Courses</h2>
      </div>
    </div>
    <div className="black-overlay" />
  </div>
  <div className="breadcrumb-main">
    <div className="container">
      <ul className="breadcrumb">
        <li><NavLink to="/">Home</NavLink></li>
        <li className="active">Courses </li>
      </ul>
    </div>
  </div>
  
  <CourseCard/>
</div>


  </>
  


  )
}

export default Courses
