import React,{useEffect} from "react";
import { NavLink } from "react-router-dom";
import SliderBottomDiv from "./SliderBottomDiv";
import AOS from "aos"
import "aos/dist/aos.css"
import AboutClg from "../About/AboutClg";
import UpComingEvent from "../Gallery/Event/UpComingEvent";
import TeachingStaff from "../Gallery/Faculties/TeachingStaff";
import AboutTestimonals from "../About/AboutTestimonals";
import AboutChoose from "../About/AboutChoose";
import CourseCard from "../Course/CourseCard";
import "./home.css"


const Home = () => {
  
       useEffect(()=>{
      AOS.init({duration:2000})
    })
    
  return (
    <>
      <div>
        {/* <div id="preloader">
          <div id="status" />
        </div> */}

        <div id="home-banner" className="carousel slide ps_indicators_l  ps_control_rotate_f kbrns_zoomInOut thumb_scroll_x swipe_x ps_easeOutQuart" data-ride="carousel" data-pause="hover" data-interval={10000} data-duration={2000}>
          <div className="carousel-inner" role="listbox">
            <div className="item active">
              <img src="assets/images/education/slide-1.jpg" alt="slider_01" />
              <div className="caption_slide1 fadeInRight" data-animation="animated fadeInRight">
                <h3>Welcome To G.S.M</h3>
                <h1>Explore the World of Our Graduates</h1>
                <NavLink className="mt_btn_white-bor" to='/about'>Read More</NavLink>
                <NavLink className="mt_btn_yellow" to='/contact'>Contact Us</NavLink>
              </div>
            </div>
            <div className="item">
              <img src="assets/images/education/slide-2.jpg" alt="slider_02" />
              <div className="caption_slide1 caption_slide1_right wow fadeInLeft" data-animation="animated fadeInLeft">
                <h3>Welcome To G.S.M</h3>
                <h1>Better education for a better world</h1>
                <NavLink className="mt_btn_white-bor" to='/about'>Read More</NavLink>
                <NavLink className="mt_btn_yellow" to='/contact'>Contact Us</NavLink>
              </div>
            </div>
            <div className="item">
              <img src="assets/images/education/slide-3.jpg" alt="slider_03" />
              <div className="caption_slide1 caption_slide1_center cap-new wow fadeInDown" data-animation="animated fadeInDown">
                <h3>Welcome To G.S.M</h3>
                <h1>Exceptional People, Exceptional Care</h1>
                <NavLink className="mt_btn_white-bor" to='/about'>Read More</NavLink>
                <NavLink className="mt_btn_yellow" to='/contact'>Contact Us</NavLink>
              </div>
            </div>
          </div>
          <a className="left carousel-control" href="#home-banner" role="button" data-slide="prev">
            <span className="fa fa-angle-left" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a className="right carousel-control" href="#home-banner" role="button" data-slide="next">
            <span className="fa fa-angle-right" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </div>

        {/* slider bottom div start */}

        <SliderBottomDiv />

        {/* slider bottom div end */}

      </div>
      <AboutClg/>
      <CourseCard/>
      <UpComingEvent/>
      <TeachingStaff/>
      <AboutTestimonals/>
      <AboutChoose/>
    </>
  );
};

export default Home;
