import React from 'react'

const AboutMission = () => {
  return (
    <div>
      <section id="mission_main" className="mission-area section-padding">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="mission-content">
                  <div className="inner-heading">
                    <h3>Mission</h3>
                    <h2>Some Information About Our College</h2>
                  </div>
                  <p>
                    Well Infrastructure & building <br /> Fully Equipped
                    Education Technology Lab <br /> Education Psychology Lab{" "}
                    <br /> Well Maintained Library <br /> Well Qualified Staff{" "}
                    <br /> Computer Lab <br /> Playground Facility for Boys &
                    Girls <br /> We are running since 2007 and having good track
                    record. Our students are well placed at different schools.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="mission-progress-bar">
                  <div className="progress-wrap">
                    <h5>Known Results</h5>
                    <div className="progress">
                      <div
                        className="progress-bar  color1"
                        role="progressbar"
                        aria-valuenow={95}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span className="bar-width">95%</span>
                      </div>
                    </div>
                  </div>
                  <div className="progress-wrap">
                    <h5>Market Research</h5>
                    <div className="progress">
                      <div
                        className="progress-bar  color1"
                        role="progressbar"
                        aria-valuenow={60}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span className="bar-width">60%</span>
                      </div>
                    </div>
                  </div>
                  <div className="progress-wrap">
                    <h5>Strategic Consulting</h5>
                    <div className="progress">
                      <div
                        className="progress-bar  color1"
                        role="progressbar"
                        aria-valuenow={90}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span className="bar-width">90%</span>
                      </div>
                    </div>
                  </div>
                  <div className="progress-wrap">
                    <h5>Digital Teaching</h5>
                    <div className="progress">
                      <div
                        className="progress-bar  color1"
                        role="progressbar"
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span className="bar-width">75%</span>
                      </div>
                    </div>
                  </div>
                  <div className="progress-wrap">
                    <h5>Online Reputation</h5>
                    <div className="progress">
                      <div
                        className="progress-bar  color1"
                        role="progressbar"
                        aria-valuenow={80}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <span className="bar-width">80%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default AboutMission
