import React from "react";
import { NavLink } from "react-router-dom";

const UpComingEvent = () => {
  return (
    <div>
      <section className="edu-events event_third section-inner">
        <div className="container">
          <div className="inner-heading">
            <h3>Upcomming Events</h3>
            <h2>Reserve your seats now</h2>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12 mar-bottom-20">
              <div className="event-left">
                <div className="events-image">
                  <img src="assets/images/places/flag.jpg" alt="" />
                  <div className="event-date">
                    <p>
                      15 <span>August</span>
                    </p>
                  </div>
                </div>
                <div className="event-donation">
                  <h3 className="mar-bottom-15">
                    <NavLink to="#">Independence Day 2023</NavLink>
                  </h3>
                  <ul className="event-time mar-bottom-15">
                    <li>
                      <i className="fa fa-clock-o" />
                      11:00 AM - 3:00 PM
                    </li>
                    <li>
                      <i className="fa fa-map-marker" />
                      Getta Gollege
                    </li>
                  </ul>
                  <p className="mar-0">
                    Join us in celebrating Independence Day at Geeta college,
                    Your presence is valued as we honor our nation's
                    independence. Let's come together and make this day
                    memorable. See you there! Jai Hind!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12 mar-bottom-20">
              <div className="event-left">
                <div className="events-image">
                  <img src="assets/images/places/dahi_handi.jpg" alt="" />
                  <div className="event-date">
                    <p>
                      7 <span>September</span>
                    </p>
                  </div>
                </div>
                <div className="event-donation">
                  <h3 className="mar-bottom-15">
                    <NavLink to="">Krishna Janmashtami </NavLink>
                  </h3>
                  <ul className="event-time mar-bottom-15">
                    <li>
                      <i className="fa fa-clock-o" />
                      11:00 AM - 3:00 PM
                    </li>
                    <li>
                      <i className="fa fa-map-marker" />
                      Geeta college
                    </li>
                  </ul>
                  <p className="mar-0">
                    Celebrate the birth of Lord Krishna with us as we indulge in
                    devotional singing, re-enactments, and cultural
                    performances. Let's come together to honor the divine and
                    embrace the spiritual essence of this auspicious day.. Join
                    us and experience the bliss of Janmashtami! Jai Shri
                    Krishna!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12 mar-bottom-20">
              <div className="event-left">
                <div className="events-image">
                  <img src="assets/images/places/ganesh.jpg" alt="" />
                  <div className="event-date">
                    <p>
                      19 <span>september</span>
                    </p>
                  </div>
                </div>
                <div className="event-donation">
                  <h3 className="mar-bottom-15">
                    <NavLink to="#">Ganesh Chaturthi</NavLink>
                  </h3>
                  <ul className="event-time mar-bottom-15">
                    <li>
                      <i className="fa fa-clock-o" />
                      11:00 AM - 3:00 PM
                    </li>
                    <li>
                      <i className="fa fa-map-marker" />
                      Geeta college
                    </li>
                  </ul>
                  <p className="mar-0">
                    Join us in celebrating the divine presence of Lord Ganesha
                    as we come together in devotion, music, and rituals. Let's
                    immerse ourselves in the vibrant atmosphere and seek
                    blessings from the remover of obstacles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12 mar-bottom-20">
              <div className="event-left">
                <div className="events-image">
                  <img src="assets/images/places/gandhi.jpg" alt="" />
                  <div className="event-date">
                    <p>
                      2 <span>october</span>
                    </p>
                  </div>
                </div>
                <div className="event-donation">
                  <h3 className="mar-bottom-15">
                    <NavLink to="#"> Mahatma Gandhi Jayanti</NavLink>
                  </h3>
                  <ul className="event-time mar-bottom-15">
                    <li>
                      <i className="fa fa-clock-o" />
                      11:00 AM - 3:00 PM
                    </li>
                    <li>
                      <i className="fa fa-map-marker" />
                      Getta Gollege
                    </li>
                  </ul>
                  <p className="mar-0">
                    Join us as we pay homage to the Father of our Nation,
                    Mahatma Gandhi, on this significant day. Let's reflect on
                    his teachings of non-violence, truth, and compassion.
                    Together, let's commemorate this extraordinary leader who
                    championed freedom, equality, and justice.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UpComingEvent;
