import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Iframe from "react-iframe";
import emailjs from "@emailjs/browser";

const Contact = () => {
  // const sendEmail = (e) => {
  //     e.preventDefault();
  // alert('submitted')
  // emailjs.sendForm("service_7jj7zcu","template_bx1i3re",e.target,"DguAuOyg-QmfU_DPr");
  // }

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  // const [message, setMessage] = useState('');

  // function Sendemail() {
  //   const Data = {to_name:name, to_email:email, phone: phone, message:message}
  //    const Service_id = "service_e3js9hm"
  // const template_id  = "template_9ys7jh9"
  // const user_id = "LX7tBr0K2l-5uqN-S"

  // emailjs.send(Service_id, template_id, Data, user_id).then(
  //   function (res) {
  //     alert("Message Send Successfully")
  //   },
  //   function (error) {
  //     console.log(error)
  //   }
  // )
  // }

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);


  function sendEmail(e) {
    e.preventDefault();

    if (isSubmitting) {
      return; 
    }
  
    setIsSubmitting(true);

    const data = {
      to_name: name,
      to_email: email,
      to_phone: phone,
      message: message,
    };

    const serviceId = "service_e3js9hm";
    const templateId = "template_9ys7jh9";
    const userId = "LX7tBr0K2l-5uqN-S";

    emailjs
      .send(serviceId, templateId, data, userId)
      .then(() => {
        alert("Message Sent Successfully")  ;
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setIsSubmitting(false);

      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  }

  return (
    <>
      <div id="blog_banner">
        <div className="page-title">
          <div className="container">
            <h2>Contact</h2>
          </div>
        </div>
        <div className="black-overlay" />
      </div>
      <div className="breadcrumb-main">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="active">Contact</li>
          </ul>
        </div>
      </div>
      <section id="mt_contact" className="contact-main section-inner">
        <div className="container">
          <div className="inner-heading">
            <h3>Contact Us</h3>
            {/* <h2>Always ahead for your solutions</h2> */}
          </div>
          <div className="map">
            {/* <div id="map" style={{height: 300, width: '100%'}} /> */}
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3803.415708196003!2d79.02955018264554!3d21.11590690814629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bff7bdb1654b%3A0x32e81262afa616ad!2sGeeta%20Shikshan%20Mahavidyalaya!5e0!3m2!1sen!2sin!4v1686841441961!5m2!1sen!2sin"
              width="100%"
              height="320px"
              id=""
              className=""
              display="block"
              position="relative"
            />
          </div>
          <div className="contact-inner">
            <div className="col-md-6">
              <div className="contact-info">
                <h3>Contact Info</h3>
                {/* <p>Lorem Ipsum is simply dummy text of the and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever</p> */}
                <ul>
                  <li>
                    <i className="fa fa-map-marker" /> Plot No.01, Dubey Lay
                    Out, Near Bazar Chowk, <br /> &nbsp; &nbsp; Jaitala Road, Nagpur 440036
                  </li>
                  <li>
                    <i className="fa fa-phone" /> +91 0712-2226700,+91 0712-
                    2226800
                  </li>
                  <li>
                    <i className="fa fa-envelope" />{" "}
                    <a
                      href="/cdn-cgi/l/email-protection"
                      className="_cf_email_"
                      data-cfemail="fe97909891be8d978a9b909f939bd09d9193"
                    >
                      info@geetacollege.org
                    </a>
                  </li>
                  {/* <li><i className="fa fa-globe" /> www.sitename.com</li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_form">
                <form id="contact_form" onSubmit={sendEmail}>
                  <input
                    type="text"
                    name="to_name"
                    id="name"
                    placeholder="Your name"
                    required
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />

                  <input
                    type="email"
                    name="to_email"
                    id="email"
                    placeholder="Your email"
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />

                  <input
                    type="tel"
                    name="to_phone"
                    id="number"
                    placeholder="Your Number"
                    required
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />

                  <textarea
                    cols={30}
                    rows={5}
                    name="to_message"
                    id="message"
                    placeholder="Message"
                    required
                    defaultValue={""}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  <button
                    type="submit"
                    className="mt_btn_yellow"
                    id="submit-btn"
                  >
                    SEND MESSAGE
                    <span className="mt_load">
                      <span />
                    </span>
                  </button>
                  <div id="msg" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
